<style lang="sass" scoped>
.link
  color: #777
  // font-weight: bold
  &:hover
    text-decoration: none
.router-link-active
  color: #466EC3
  font-weight: bold

.container-property
  // z-index: 1000
  .index-nav
    z-index: 1000
  .container-nav
    position: fixed
    left: 0
    top: 0
    width: 300px
    height: 100vh
    z-index: 10
  //   position: fixed
  //   left: 0
  //   top: 0
  //   width: 70px
  //   height: 100vh
  //   z-index: 1000
  //   background-color: darken(#f8f9fa, 7%)
  //   // border-right: solid 1px darken(#f8f9fa, 5%)

  //   .link
  //     width: 100%
  //     font-size: 12px
  //     font-weight: bold
  //     // color: darken(#f8f9fa, 60%) !important
  //     &.router-link-active
  //       color: #007bff !important
  //     &.active
  //       // background-color: #f8f9fa
  //       // background-color: #fff
  //       // color: #466EC3 !important
  //       // font-weight: 800
  //       // color: #007bff !important

  //     &:hover
  //       // background-color: #f8f9fa
  //       // text-decoration: none
  //       // color: #007bff !important
.block
  a
    color: #fff
    // &:hover
    //   background-color: rgba(255,255,255,0.2)
    //   text-decoration: none
.block-scroll
  overflow: scroll
  height: calc(100vh - 53px - 64px)
.container-customer
  font-size: 12px
  .link
    color: darken(#f8f9fa, 60%)
    font-weight: bold
    line-height: 2rem
    &.active
      color: darken(#f8f9fa, 80%)
      background-color: darken(#f8f9fa, 7%)
      // font-weight: 700
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.notification
  position: absolute
  right: 0.5rem
  top: 0.5rem
  // right: 3px
  // top: 3px
  z-index: 1000
  font-size: 12px
  .count
    // margin-right: -25px
    // float: right
    position: relative
    // top: -10px
    top: 0px
    right: 5px
    font-size: 12px

.link-channel
  a
    font-weight: 300
    &.active
      font-weight: 700
.block
  a
    &:hover
      text-decoration: none
.block-menu
  a
    padding: 0.5rem 1rem 0.5rem 1.5rem
    font-size: 12px
    &:hover
      background-color: rgba(255,255,255,.05)
.block-label
  padding: 0.5rem 1rem 0.5rem 1rem
  opacity: 0.3
  font-size: 11px
  border-top: solid 1px rgba(0,0,0,.4)
.container-right
  margin-left: 300px
  min-height: 100vh
.container-search
  height: 53px
.block-search
  .form-control
    opacity: 0.5
    border: 0
    box-shadow: none
    font-size: 12px
    color: #fff
    padding: 1rem
    &:focus, &:hover
      opacity: 1

  .block-icon
    opacity: 0.5

.block-menu
  .router-link-active
    background-color: rgba(255,255,255,.1)
.btn-more
  background-color: rgba(255,255,255,0)
  &:active
  &:hover
    background-color: rgba(255,255,255,.1) !important
    color: rgba(255,255,255,.9) !important
.block-draggable
  &.ghost
    opacity: 0 !important
  .handle-grip
    position: relative
    left: 5px
    top: 5px
    padding-right: 5px
    float: left
    cursor: move
    opacity: 0
    // transition: opacity ease-in-out 300ms
  &:hover
    .handle-grip
      opacity: 0.5 !important
      // transition: opacity ease-in-out 300ms

</style>
<template lang="pug">
div.container-property
  .container-nav.bg-dark.d-print-none
    .block.block-header.text-white.py-2.px-2.clearfix(style='background-color: rgba(255,255,255,.05);')
      router-link.p-2.float-left(:to='`/property/${property_id}/activity`' style='font-weight: normal; color: #fff'): strong {{property.name}}

      span.float-right
        dropdown(:active='dropdown_account_active')
          template(slot='toggler')
            button.btn.btn-dark.btn-sm.border-0.btn-more(type='button' style='font-size: 12px; font-weight: 700; padding: 9px 13px; color: rgba(255,255,255,.3); margin-top: 0px')
              | 계정
              //- b-icon-chevron-down(style='font-weight: bold')

          dropdown-content
            //- dropdown-block.mt-1
              input.form-control.form-control-sm.form-dropdown(type='text' value='목록 이름')
            router-link.link-dropdown.text-left.p-2.d-block.text-dark(to='/property' exact) 다른 workspace 이동
            router-link.link-dropdown.text-left.p-2.d-block.text-dark(to='/logout') 로그아웃
            //- .border-top(style='opacity: 0.3')
            //- router-link.link-dropdown.text-left.p-2.d-block.text-dark(:to='`/property/${property_id}/manage`' @click.exact='click_dropdown_account_active') Workspace 설정
            //- hr.my-2
      //- span.float-right(style='font-size: 12px') 계정
        b-icon-chevron-down.ml-1(style='font-size: 10px')
      //- router-link.p-2.float-right(:to='`/property/${property_id}/activity`') 설정
    .block.text-white.clearfix.block-search
      //- form.clearfix(@submit='search_customer')
        span.float-left.ml-2.p-2.block-icon(style='font-size: 10px;')
          b-icon-search
        span.float-left
          input.form-control.bg-dark.pl-0(type='text' placeholder='고객ID 고객연락처 고객명')


      form.clearfix(@submit='search_all')
        span.float-left.ml-2.p-2.block-icon(style='font-size: 10px;')
          b-icon-search
        span.float-left
          input.form-control.bg-dark.pl-0(type='text' placeholder='고객·내용 검색')
    .block-scroll
      //- pre {{$store.state.property.menu_config}}
      template(v-if='$store.state.property && $store.state.property.menu_config && $store.state.property.menu_config.blocks.length')
        .block.text-white.clearfix.block-label
          strong 즐겨찾기
        draggable(:class='{dragging: drag}' v-model='$store.state.property.menu_config.blocks' handle='.handle' v-bind='drag_options' @start='drag = true' @end='drag_end')
          transition-group(type='transition' :name="!drag ? 'flip-list' : null")
            .block.text-white.clearfix.block-menu.block-draggable.handle(v-for='block in $store.state.property.menu_config.blocks' :key='block.ts')
              span.handle-grip: b-icon-grip-horizontal
              router-link.d-block(:to='get_link_href(block)') {{block.name}}
      .block.text-white.clearfix.block-label
        strong 일반
      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/activity`') 고객목록

      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/message/all`') 메시지 발송내역

      .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/manage`') 설정

      .pb-4
      //- .block.text-white.clearfix.block-menu
        router-link.d-block(:to='`/property/${property_id}/message/reply`') 답변도착
          span.badge.badge-primary.float-right 3
    //- .block.text-white.clearfix.block-label
    //-   strong 고객
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 필터1
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 필터2

    //- .block.text-white.clearfix.block-label
    //-   strong 입력
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름1
    //-     span.badge.badge-info.float-right SQL
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름2
    //-     span.badge.badge-info.float-right SQL
    //- .block.text-white.clearfix.block-menu
    //-   a.d-block(href='#') 이름3
    //-     span.badge.badge-info.float-right SQL


    //- .block.text-white.clearfix.block-label
    //-   //- strong 기타
    //- //- .block.text-white.clearfix.block-menu
    //-   router-link.d-block(:to='`/property/${property_id}/manage`') 설정
    //- .block.text-white.clearfix.block-menu.w-100(style='position: absolute; bottom: 0px')
    //-   //- router-link.d-block(:to='`/property`' active-class='') 다른 workspace 이동
    //-   router-link.d-block(:to='`/property/${property_id}/manage`') 설정
    //- .block.text-white.clearfix.block-menu
      router-link.d-block(:to='`/property/${property_id}/manage`') 알림
  .container-right.bg-light(style='height: 100vh; overflow: scroll; -webkit-overflow-scrolling: touch')
    .p-3
      router-view
  //- .container-nav
  //- .container-nav
    .d-flex.flex-column(style='height: 100%')
      div(style='height: 300px')
        .d-flex.flex-column
          router-link.link.p-2.py-4.text-center.text-dark(to='/property' exact)
            span(style='font-size: 14px; line-height: 24px; font-weight: 900; opacity: .3 ')
              em noitaler
          router-link.link.btn.px-0.py-4.text-dark(:to='`/property/${property.id}/customer`') 고객
          router-link.link.btn.px-0.py-4.text-dark(:to='`/property/${property.id}/message`') 메시지
          router-link.link.btn.px-0.py-3.text-dark(:to='`/property/${property.id}/order`') 주문
            br
            small 구버전
          router-link.link.btn.px-0.py-3.text-dark(:to='`/property/${property.id}/views`') 고객뷰
            br
            small 구버전
      div.mt-auto()
        .d-flex.flex-column
          router-link.link.btn.p-2.py-4.text-center.text-dark(:to='`/property/${property.id}/manage`') 설정
          //- router-link.link.btn.p-2.py-4.text-center.text-dark(:to='`/property/${property.id}/profile`') 프로필
          router-link.link.btn.p-2.py-4.text-center.text-dark(:to='`/`' exact) 프로필
          a.link.btn.p-2.py-4.text-center.text-dark(href='#') 도움말

  //- router-view

  //- button.btn.btn-default.notification.text-dark.py-2.px-1.async(:class='{done:notification_loaded}')
    //- b-icon-bell-fill
    //- br
    | 알림
    span.ml-2.count.badge.badge-default(:class='{"badge-danger": count>0}') {{count}}


  //- .p-4.border-bottom
    //- router-link.mr-4.float-right(:to='`/property`') 목록
    router-link.mr-4.float-right.link(:to='`/property/${property.id}/manage`') 설정
    //- router-link.mr-4.float-right(:to='`/manage`') 로그아웃
    a.mr-4.float-right.link#popover-1(@click.prevent.stop='') 알림
      span.ml-2.badge.badge-light.text-primary.p-2 0

    b-popover(target='popover-1' placement='bottom' triggers='hover focus' custom-class='shadow border-light')
      //- template.border(#title).bg-dark 알림 목록
      div.p-4
        //- (style='width: 400px; height: 340px; overflow: scroll')
        div.text-center(style='width: 200px !important; height: 10rem') 알림이 없습니다.

    div.async(:class='{done:loaded}')
      strong.mr-4 {{property.name || '(이름없음)'}}
      router-link.link(:to='`/property/${property.id}/order`') 고객관리
      router-link.link.ml-4(:to='`/property/${property.id}/views`') 고객뷰
      //- router-link.ml-4.text-dark(:to='`/property/${property.id}`')
      //-   span 리뷰 (QA)


</template>

<script>

import draggable from 'vuedraggable'

import Dropdown from "@/components/Dropdown";
import DropdownContent from "@/components/DropdownContent";
import DropdownItem from "@/components/DropdownItem";
import DropdownBlock from "@/components/DropdownBlock";

export default {
  name: 'index',
  props: ['property_id'],
  components: {
    Dropdown, DropdownContent, DropdownItem, DropdownBlock,
    draggable,
  },
  computed: {
    session() {
      return this.$store.state.session
    },
    property() {
      return this.$store.state.property
    },
  },
  data() {
    return {
      loaded: false,

      count: null,
      notification_loaded: false,

      dropdown_account_active: 1,

      drag: false,

      drag_options: {
        animation: 200,
        ghostClass: 'ghost',
      },
    }
  },
  async mounted() {
    await this.$store.dispatch('status flows', this.property_id)
    await this.$store.dispatch('users by id', this.property_id)
    await this.$store.dispatch('documents', this.property_id)
    setTimeout(() => {
      this.count = 0
      // this.count = 12
      this.notification_loaded = true

      if (window.localStorage) {
        window.localStorage['noitaler.latest_property_id'] = this.property_id
        window.localStorage['noitaler.latest_user_id'] = this.session.id
      }
    }, 300);
    this.loaded = true
  },
  methods: {
    async drag_end() {
      try {
        this.drag = false
        const menu_config = this.$store.state.property.menu_config
        const r = await this.$http.put(`/v1/property/${this.property_id}/menu_config`, {
          menu_config,
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '즐겨찾기 저장 실패')
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    get_saved_filter_prefix(saved_filter_id) {
      return saved_filter_id ? '-'+saved_filter_id : ''
    },
    get_link_href(block) {
      if (block.saved_filter_id) {
        return `/property/${this.property_id}/customer/${block.document_id}${this.get_saved_filter_prefix(block.saved_filter_id)}`
      } else {
        return `/property/${this.property_id}/customer/${block.document_id}`
      }
    },
    // get_link_label(block) {
    //   const d = this.$store.state.documents_by_id[block.document_id]
    //   return d.name
    // },
    search_all() {

    },
  },
}
</script>
